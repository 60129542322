import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Button,
  Input,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"

function Users() {
  const history = useHistory()

  const Actinid = data => {
    // sessionStorage.setItem("Productsids", data._id)
    history.push("/EditProduct")
  }

  const Actinid1 = data => {
    // sessionStorage.setItem("Productsids", data._id)
    // history.push("/ViewBookings")
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="GBS" breadcrumbItem="Pending Bookings" />{" "}
          <Row>
            <Col md="12">
              {" "}
              <Card className="mini-stats-wid">
                <CardBody>
                  <Row className="mb-2">
                    <Col>
                      <h5>Pending Bookings</h5>
                    </Col>
                  </Row>
                  <Row className="mt-1 mb-3">
                    <Col>
                      <div style={{ float: "right" }}>
                        <Input
                          className="form-control"
                          placeholder="search...."
                          type="search"
                          style={{ width: "250px" }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="table-rep-plugin">
                    <div className="table-responsive mb-0">
                      <Table className="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th>S.NO</th>
                            <th>User Name</th>
                            <th>User Phone</th>
                            <th>User Email</th>
                            <th>Product Name</th>
                            <th>Image</th>
                            <th>Name</th>
                            <th>SZ CAT No</th>
                            <th>CAS No</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>Peruri revanth</td>
                            <td>8309280849 </td>
                            <td>Peruri@gmail.com</td>
                            <td>Abacavir</td>
                            <td>-</td>
                            <td>Abacavir Sulfate</td>
                            <td>SZ-A049001</td>
                            <td>188062-50-2</td>
                            <td>
                              <Button
                                onClick={() => {
                                  Actinid()
                                }}
                                style={{
                                  padding: "6px",
                                  margin: "3px",
                                }}
                                size="sm"
                                className="m-1"
                                outline
                                color="success"
                              >
                                <i className="bx bx-edit"></i>
                              </Button>
                              <Button
                                onClick={() => {
                                  Actinid1()
                                }}
                                style={{
                                  padding: "6px",
                                  margin: "3px",
                                }}
                                size="sm"
                                className="m-1"
                                outline
                                color="info"
                              >
                                <i className="fas fa-eye"></i>
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>{" "}
    </React.Fragment>
  )
}

export default Users
