import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Modal,
  Form,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Product from "../../assets/images/samplefiles.csv"
import { ToastContainer, toast } from "react-toastify"
import { Link, useHistory } from "react-router-dom"
import ReactPaginate from "react-paginate"
import { URLS } from "../../Url"
import axios from "axios"

function Ventures() {
  const [Actin, setActin] = useState([])

  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    GetProducts()
    datass()
  }, [])

  const GetProducts = () => {
    var token = datas

    axios
      .post(
        URLS.GetProduct,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.products)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const Actinid = data => {
    sessionStorage.setItem("Productsids", data._id)
    history.push("/EditProduct")
  }

  const Actinid1 = data => {
    sessionStorage.setItem("Productsids", data._id)
    history.push("/ViewProduct")
  }

  const datass = () => {
    const location = sessionStorage.getItem("tost")
    if (location != "") {
      toast(location)
      sessionStorage.clear()
    } else {
      sessionStorage.clear()
    }
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const [Searchs, setSearchs] = useState([])

  const Search = e => {
    let myUser = { ...Searchs }
    myUser[e.target.name] = e.target.value
    setSearchs(myUser)
    var token = datas

    axios
      .post(
        URLS.GetProductSearch + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.products)
      })
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      DeleteBanner(data)
    }
  }
  const DeleteBanner = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(URLS.DeleteProduct + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetProducts()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [Files1, setFiles1] = useState("")
  const [modal_small, setmodal_small] = useState(false)

  const changeHandler1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "xlsx" || type == "csv" || type == "CSV" || type == "XLSX") {
      setFiles1(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    Bluk()
  }

  const Bluk = () => {
    var token = datas
    const dataArray = new FormData()
    for (let i = 0; i < Files1.length; i++) {
      dataArray.append("medicinefile", Files1[i])
    }
    axios
      .post(URLS.BlukUploadProduct, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setmodal_small(false)
            GetProducts()
            clearForm1()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const clearForm1 = () => {
    setFiles1({
      image: "",
    })
  }

  const getpopup = () => {
    tog_small()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="GBS Admin" breadcrumbItem="Products" />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Link to="/AddProduct">
                        <Button color="primary">
                          New Products <i className="bx bx-plus-circle"></i>
                        </Button>
                      </Link>

                      <Button
                        onClick={() => {
                          getpopup()
                        }}
                        className="mx-2"
                        color="success"
                      >
                        <i className="bx bx-edit "></i> Bulk Upload
                      </Button>

                      <a href={Product} className="text-white">
                        <Button className="mx-1" color="danger">
                          Sample Download
                        </Button>
                      </a>
                    </Col>

                    <Col>
                      <div style={{ float: "right" }}>
                        <Input
                          type="search"
                          name="search"
                          value={Searchs.search}
                          onChange={Search}
                          className="form-control"
                          placeholder="Search.."
                          autoComplete="off"
                        />
                      </div>
                    </Col>
                  </Row>

                  <div className="table-rep-plugin mt-4 table-responsive">
                    <Table hover className="table table-bordered mb-4 ">
                      <thead>
                        <tr className="text-center">
                          <th>SlNo</th>
                          <th>Product Name</th>
                          <th>Image</th>
                          <th>Name</th>
                          <th>SZ CAT No </th>
                          <th>CAS No</th>
                          <th>Mol.F.</th>
                          <th>Mol.Wt. </th>
                          <th>Inv.Status </th>
                          <th>Shipping Condition</th>
                          <th>Rel. CAS No </th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key} className="text-center">
                            <td>{(pageNumber - 1) * 5 + key + 6}</td>
                            <td>{data.productcategoryName}</td>
                            <td>
                              <img
                                src={URLS.Base + data.image}
                                style={{ width: "80px" }}
                              />
                            </td>
                            <td>{data.name}</td>
                            <td>{data.catNo}</td>
                            <td>{data.casNo}</td>
                            <td>{data.molF}</td>
                            <td>{data.molWt}</td>
                            <td>{data.invStatus}</td>{" "}
                            <td>{data.shippingCondition}</td>
                            <td>{data.resCasno}</td>
                            <td>
                              <Button
                                onClick={() => {
                                  Actinid(data)
                                }}
                                style={{
                                  padding: "6px",
                                  margin: "3px",
                                }}
                                size="sm"
                                className="m-1"
                                outline
                                color="success"
                              >
                                <i className="bx bx-edit"></i>
                              </Button>
                              <Button
                                onClick={() => {
                                  Actinid1(data)
                                }}
                                style={{
                                  padding: "6px",
                                  margin: "3px",
                                }}
                                size="sm"
                                className="m-1"
                                outline
                                color="info"
                              >
                                <i className="fas fa-eye"></i>
                              </Button>
                              <Button
                                onClick={() => {
                                  manageDelete(data)
                                }}
                                style={{
                                  padding: "6px",
                                  margin: "3px",
                                }}
                                color="danger"
                                outline
                              >
                                <i className="bx bx-trash"></i>
                              </Button>{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div
                      className="d-flex mt-3 mb-1"
                      style={{ float: "right" }}
                    >
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            size="md"
            isOpen={modal_small}
            toggle={() => {
              tog_small()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Bulk Upload
              </h5>
              <button
                onClick={() => {
                  setmodal_small(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  handleSubmit1(e)
                }}
              >
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Bulk Upload Product <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    name="image"
                    value={Files1.image}
                    onChange={changeHandler1}
                  />
                </div>

                <div style={{ float: "right" }}>
                  <Button
                    onClick={() => {
                      setmodal_small(false)
                    }}
                    color="danger"
                    type="button"
                  >
                    Cancel <i className="fas fa-times-circle"></i>
                  </Button>
                  <Button className="m-1" color="primary" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
