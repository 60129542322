import React, { useState, useEffect } from "react"
import { CardBody, Container, Row, Col, Card, Button, Table } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { URLS } from "../../Url"
import axios from "axios"

function RecruitView() {
  const history = useHistory()

  // useEffect(() => {
  //   GetOneActins()
  // }, [])

  const [form, setform] = useState([])

  const Actinid = sessionStorage.getItem("Productsids")

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const GetOneActins = () => {
    const data = {
      id: Actinid,
    }

    var token = datas
    axios
      .post(URLS.GetoneProduct, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setform(res.data.product[0])
      })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="View Bookings" />
          <Row>
            <Col>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>
          </Row>
          <Card className="mb-5">
            <CardBody>
              <Row>
                <h5 className="mb-5 text-primary">User Details : </h5>
                <Col lg={4}>
                  <ul className="list-unstyled vstack gap-3 mb-0">
                    <li>
                      <div className="d-flex ">
                        <i className="bx bxs-buildings font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">Name: </h6>
                          <span className="text-muted">Peruri revanth </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex mt-3">
                        <i className="bx bx-money font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">User Phone:</h6>
                          <span className="text-muted">8309280849</span>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="d-flex  mt-3">
                        <i className="bx bxs-home-circle font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">User Email:</h6>
                          Peruri@gmail.com
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex  mt-3">
                        <i className="bx bxs-user-pin font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">
                            Alternate Number:
                          </h6>
                          8309280849
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="d-flex  mt-3">
                        <i className="bx bx-bookmark font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">Date Of Order :</h6>
                          <span className="text-muted">2023-02-15</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col lg={8}>
                  <ul className="verti-timeline list-unstyled">
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 ">State Name</h6>
                            <p className="text-muted">Telangana</p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 ">District Name </h6>
                            <p className="text-muted">Hyderabad</p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 ">Zip Code</h6>
                            <p className="text-muted">500050</p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 ">Address</h6>
                            <p className="text-muted">
                              Bhavana Enclave, Rd Number 7, Madhavapuri Hills,
                              PJR Enclave, PJR Layout.
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card className="mb-5">
            <CardBody>
              <Row>
                <Col lg={4}>
                  <h5 className="mb-3 text-primary">Product Details : </h5>
                  <ul className="list-unstyled vstack gap-3 mb-0">
                    <img src={URLS.Base + form.image} height="150px"></img>
                  </ul>
                </Col>
                <Col lg={8}>
                  <div className="table-rep-plugin mt-4 table-responsive">
                    <Table hover className="table table-bordered mb-4 ">
                      <thead>
                        <tr className="text-center">
                          <th>Product Name</th>
                          <td>{form.productcategoryName}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Image</th>
                          <td>
                            <img
                              src={URLS.Base + form.image}
                              style={{ width: "80px" }}
                            />
                          </td>
                        </tr>
                        <tr className="text-center">
                          <th>Name</th>
                          <td>{form.name}</td>
                        </tr>
                        <tr className="text-center">
                          <th>SZ CAT No </th>
                          <td>{form.catNo}</td>
                        </tr>
                        <tr className="text-center">
                          <th>CAS No</th>
                          <td>{form.casNo}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Mol.F.</th>
                          <td>{form.molF}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Mol.Wt. </th>
                          <td>{form.molWt}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Inv.Status </th>
                          <td>{form.invStatus}</td>{" "}
                        </tr>
                        <tr className="text-center">
                          <th>Shipping Condition</th>
                          <td>{form.shippingCondition}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Amount</th>
                          <td>{form.amount}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Rel. CAS No </th>
                          <td>{form.resCasno}</td>
                        </tr>
                      </thead>
                    </Table>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RecruitView
