import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody, Table } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ReactApexChart from "react-apexcharts"
import { URLS } from "../../Url"
import axios from "axios"

const Dashboard = () => {
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [Actin, setActin] = useState([])

  const [Customer, setCustomer] = useState([])

  useEffect(() => {
    GetProducts()
  }, [])

  const GetProducts = () => {
    var token = datas

    axios
      .post(
        URLS.GetDashboard,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data)
        setCustomer(res.data.latestcustomers)
      })
  }

  const reports = [
    {
      title: "Products",
      iconClass: "bx-copy-alt",
      description: Actin.products,
    },
    {
      title: "Orders",
      iconClass: "bx-purchase-tag-alt",
      description: Actin.orders,
    },
    {
      title: "Users",
      iconClass: "bx-purchase-tag-alt",
      description: Actin.users,
    },
    { title: "Amount", iconClass: "bx-archive-in", description: Actin.orders },
  ]

  const series = [
    {
      name: "Orders",
      data: [74, 83, 102, 97, 86, 106, 93, 114, 94],
    },
    {
      name: "Revenue",
      data: [37, 42, 38, 26, 47, 50, 54, 55, 43],
    },
  ]
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "25%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    colors: ["#34c38f", "#556ee6", "#f46a6a"],
    xaxis: {
      categories: [
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
      ],
    },

    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"GBS Admin"} breadcrumbItem={"Dashboard"} />

          <Row>
            <Col xl="12">
              <Row>
                {reports.map((report, key) => (
                  <Col md="3" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>

            <Col xl="12">
              <Card>
                <CardBody>
                  <ReactApexChart
                    options={options}
                    series={series}
                    type="bar"
                    height={350}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Row>
                <h5>Latest Customers</h5>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <h5 className="mt-3 mb-3">Latest Customers</h5>
                      <div className="table-rep-plugin mt-4 table-responsive">
                        <Table hover className="table table-bordered mb-4 ">
                          <thead>
                            <tr className="text-center">
                              <th>SlNo</th>
                              <th>Name</th>
                              <th>Order Id</th>
                              <th>Amount</th>
                              <th>Total</th>
                              <th>Date</th>
                              <th>Time</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Customer.map((data, key) => (
                              <tr key={key} className="text-center">
                                <td>{key + 1}</td>
                                <td>{data.userName}</td>
                                <td>{data.orderId}</td>
                                <td>{data.amount}</td>
                                <td>{data.subTotal}</td>
                                <td>{data.date}</td>
                                <td>{data.time}</td>
                                <td>
                                  {data.status == "active" ? (
                                    <>
                                      <span className="badge bg-success ">
                                        {data.status}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span className="badge bg-danger ">
                                        {data.status}
                                      </span>
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
