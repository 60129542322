import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>

            <li>
              <Link to="/dashboard">
                <i className="bx bxs-customize"></i>
                <span>{props.t("Dashboards")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-carousel"></i>
                <span>{props.t("Home slider")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/HomeSlider">{props.t("Home slider")}</Link>
                </li>
                <li>
                  <Link to="/HomeAboutUs">{props.t("Home About Us")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/About">
                <i className="bx bxs-bookmarks"></i>
                <span>{props.t("About Us")}</span>
              </Link>
            </li>

            <li>
              <Link to="/Falicity">
                <i className="bx bxs-badge-check"></i>
                <span>{props.t("Falicity")}</span>
              </Link>
            </li>

            <li>
              <Link to="/Clients">
                <i className="bx bxs-quote-alt-right"></i>
                <span>{props.t("Clients")}</span>
              </Link>
            </li>
            <li>
              <Link to="/Testimonials">
                <i className="bx bxs-quote-alt-right"></i>
                <span>{props.t("Testimonials")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-cart"></i>
                <span>{props.t("Products")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/ProductCat">{props.t("Products Category")}</Link>
                </li>
                <li>
                  <Link to="/AddProduct">{props.t("Add Product")}</Link>
                </li>

                <li>
                  <Link to="/Product">{props.t("Products")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-wallet-alt"></i>
                <span>{props.t("Bookings Managment")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/PendingBookings">
                    {props.t("Pending Bookings")}
                  </Link>
                </li>
                <li>
                  <Link to="/CompleatedBookings">
                    {props.t("Completed Bookings")}
                  </Link>
                </li>
                <li>
                  <Link to="/Payments">
                    {props.t("Payments")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/Users">
                <i className="bx bxs-user-detail"></i>
                <span>{props.t("Users")}</span>
              </Link>
            </li>

            <li>
              <Link to="/Contactus">
                <i className="bx bxs-message-rounded-dots"></i>
                <span>{props.t("Contact Us")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="fas fa-cogs"></i>
                <span>{props.t("Settings")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/Faqs">{props.t("FAQ'S")}</Link>
                </li>

                <li>
                  <Link to="/Privacy">{props.t("Privacy Policy")}</Link>
                </li>
                <li>
                  <Link to="/RefundPolicy">{props.t("Refund Policy")}</Link>
                </li>
                <li>
                  <Link to="/Terms">{props.t("Terms & Conditions")}</Link>
                </li>
              </ul>
            </li>

            {/* <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-carousel"></i>
                <span>{props.t("Home slider")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/MainBanners">{props.t("Home slider")}</Link>
                </li>
                <li>
                  <Link to="/Secondarybanner">{props.t("About Us")}</Link>
                </li>
              </ul>
            </li> */}

            {/* <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-cart"></i>
                <span>{props.t("Products")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/Products">{props.t("Products")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-money"></i>
                <span>{props.t("Wallet")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/Payoutrequests">{props.t("Wallet")}</Link>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
