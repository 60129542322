import React, { useState, useEffect } from "react"
import { CardBody, Container, Row, Col, Card, Button, Table } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { URLS } from "../../Url"
import axios from "axios"

function RecruitView() {
  const history = useHistory()

  useEffect(() => {
    GetOneActins()
  }, [])

  const [form, setform] = useState([])

  const Actinid = sessionStorage.getItem("Productsids")

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const GetOneActins = () => {
    const data = {
      id: Actinid,
    }

    var token = datas
    axios
      .post(URLS.GetoneProduct, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setform(res.data.product[0])
      })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="View Product" />
          <Row>
            <Col>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>
          </Row>

         
          <Card className="mb-5">
            <CardBody>
              <Row>
                <Col lg={4}>
                  <h5 className="mb-3 text-primary">Product Details : </h5>
                  <ul className="list-unstyled vstack gap-3 mb-0">
                    <img src={URLS.Base + form.image} height="150px"></img>
                  </ul>
                </Col>
                <Col lg={8}>
                  <div className="table-rep-plugin mt-4 table-responsive">
                    <Table hover className="table table-bordered mb-4 ">
                      <thead>
                        <tr className="text-center">
                          <th>Product Name</th>
                          <td>{form.productcategoryName}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Image</th>
                          <td>
                            <img
                              src={URLS.Base + form.image}
                              style={{ width: "80px" }}
                            />
                          </td>
                        </tr>
                        <tr className="text-center">
                          <th>Name</th>
                          <td>{form.name}</td>
                        </tr>
                        <tr className="text-center">
                          <th>SZ CAT No </th>
                          <td>{form.catNo}</td>
                        </tr>
                        <tr className="text-center">
                          <th>CAS No</th>
                          <td>{form.casNo}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Mol.F.</th>
                          <td>{form.molF}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Mol.Wt. </th>
                          <td>{form.molWt}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Inv.Status </th>
                          <td>{form.invStatus}</td>{" "}
                        </tr>
                        <tr className="text-center">
                          <th>Shipping Condition</th>
                          <td>{form.shippingCondition}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Amount</th>
                          <td>{form.amount}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Rel. CAS No </th>
                          <td>{form.resCasno}</td>
                        </tr>
                      </thead>
                    </Table>
                  </div>
                </Col>
              </Row>
              <h5 className="mb-3 mt-5 text-primary">
                <hr></hr>
                Product Overview :-{" "}
              </h5>
              <hr></hr>
              <Row>
                <Col lg={12}>
                  <div style={{ width: "100%" }}>
                    <div
                      className="mt-2"
                      dangerouslySetInnerHTML={{
                        __html: form.overview,
                      }}
                    ></div>
                  </div>
                </Col>
              </Row>

              <h5 className="mb-3 mt-5 text-primary">
                <hr></hr>
                Product Reference :-{" "}
              </h5>
              <hr></hr>
              <Row>
                <Col lg={12}>
                  <div style={{ width: "100%" }}>
                    <div
                      className="mt-2"
                      dangerouslySetInnerHTML={{
                        __html: form.reference,
                      }}
                    ></div>
                  </div>
                </Col>
              </Row>

              <h5 className="mb-3 mt-5  text-primary">
                <hr></hr>
                Product Technical Data :-{" "}
              </h5>
              <hr></hr>
              <Row className="mb-5">
                <Col lg={12}>
                  <div style={{ width: "100%" }}>
                    <div
                      className="mt-2"
                      dangerouslySetInnerHTML={{
                        __html: form.technicalData,
                      }}
                    ></div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RecruitView
