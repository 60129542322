import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Users from "pages/App users/Users"
import HomeSlider from "pages/Home/HomeSlider"
import About from "pages/Home/About"
import Terms from "pages/Settings/Terms"
import Privacy from "pages/Settings/Privacy"
import Faqs from "pages/Settings/Faqs"
import RefundPolicy from "pages/Settings/RefundPolicy"
import Falicity from "pages/Facality/Falicity"
import Contactus from "pages/Settings/Contactus"
import Testimonials from "pages/Facality/Testimonials"
import HomeAboutUs from "pages/Home/HomeAboutUs"

import ProductCat from "pages/Products/ProductCat"
import AddProduct from "pages/Products/AddProduct"
import EditProduct from "pages/Products/EditProduct"
import Product from "pages/Products/Product"
import ViewProduct from "pages/Products/ViewProduct"

import Clients from "pages/Settings/Clients"

import PendingBookings from "pages/Bookings/PendingBookings"
import CompleatedBookings from "pages/Bookings/CompleatedBookings"
import ViewBookings from "pages/Bookings/ViewBookings"
import Payments from "pages/Bookings/Payments"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/Testimonials", component: Testimonials },
  { path: "/HomeSlider", component: HomeSlider },
  { path: "/About", component: About },
  { path: "/Terms", component: Terms },
  { path: "/Privacy", component: Privacy },
  { path: "/Faqs", component: Faqs },
  { path: "/RefundPolicy", component: RefundPolicy },
  { path: "/Falicity", component: Falicity },
  { path: "/Contactus", component: Contactus },
  { path: "/Users", component: Users },
  { path: "/HomeAboutUs", component: HomeAboutUs },

  { path: "/PendingBookings", component: PendingBookings },
  { path: "/CompleatedBookings", component: CompleatedBookings },
  { path: "/ViewBookings", component: ViewBookings },
  { path: "/Payments", component: Payments },

  { path: "/ProductCat", component: ProductCat },
  { path: "/AddProduct", component: AddProduct },
  { path: "/EditProduct", component: EditProduct },
  { path: "/Product", component: Product },
  { path: "/ViewProduct", component: ViewProduct },
  { path: "/Clients", component: Clients },
  
  { path: "/profile", component: UserProfile },
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { publicRoutes, authProtectedRoutes }
